import React from "react";
import { Footer, Navbar } from "../components";

export default function About() {
  return (
    <>
      {/*
      <section className="about_header_section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="about_section_header">About 2K Virtual World</h3>
            </div>
          </div>
        </div>
      </section>
      <Footer /> */}
      <section>
        <div className="container about_header_section">
          <div className="row" style={{ width: "100%" }}>
            <div
              className="col-lg-12"
              style={{ justifyContent: "end", display: "flex" }}
            >
              <h3 className="about_section_header">About 2K Virtual World</h3>
              {/* <h3 className="about_section_header">About 2K Virtual World</h3> */}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-3 mb-3">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="about_design">
                We Create, Design and Make it Real
              </h3>
              <p className="about_design_para">
                The disposition of the company lies in providing the clients
                with precise and innovative software solutions not only to help
                them grow but also making them a conspicuous name in their
                field. The company’s objective is to excel and be a leader in
                its field by paving its growth foundation with client
                satisfaction and keeping pace with changing market needs and
                trends.
              </p>
              <p className="about_text">If one can do, we too can do!</p>
              <br/>
              <p className="about_text">If no one can do, we must do!</p>
            </div>
            <div className="col-lg-6">
              <h3 className="about_design">What does 2K Virtual World do?</h3>
              <p className="about_design_para">
                In today's digital landscape, websites play a pivotal role in
                defining the online identity of your business, regardless of its
                scale or industry. At 2K Virtualworld, we don't just offer web
                development and web Application services; we're your dedicated
                technology partner, driven by a singular vision to unleash
                digital potential through scalable, cutting-edge, secure, and
                unparalleled web development solutions.
              </p>
            </div>
            {/* <div className="col-lg-6">
              <img src="" />
            </div> */}
          </div>
        </div>
      </section>
      {/* <section>
        <div className="container mt-0 mb-5">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="about_design">What does 2K Virtual World do?</h3>
              <p className="about_design_para">
                In today's digital landscape, websites play a pivotal role in
                defining the online identity of your business, regardless of its
                scale or industry. At 2K Virtualworld, we don't just offer web
                development and web Application services; we're your dedicated
                technology partner, driven by a singular vision to unleash
                digital potential through scalable, cutting-edge, secure, and
                unparalleled web development solutions.
              </p>
            </div>
            <div className="col-lg-6">
              <img src="" />
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
    </>
  );
}
